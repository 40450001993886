import LandingPage from './components/LandingPage'
import { Route, Routes } from 'react-router-dom'
import AboutPage from './components/About'
import LegalMentionPage from './components/LegalMention'
import ContactPage from './components/Contact'
import CareerPage from './components/Career'

export default function BaseRouter() {
    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={<LandingPage/>}
                />
                <Route
                    path="/about"
                    element={<AboutPage/>}
                />
                <Route
                    path="/legal"
                    element={<LegalMentionPage/>}
                />
                <Route
                    path="/contact"
                    element={<ContactPage/>}
                />
                <Route
                    path="/career"
                    element={<CareerPage/>}
                />

            </Routes>
        </>
    )
}