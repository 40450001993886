import React, { useCallback, useEffect, useState } from 'react'
import Overlay from './ToggleMenu'

const LandingPage = (callback, deps) => {
    const smallScreen = window.innerWidth < 600;
    const mediumScreen = window.innerWidth >= 600 && window.innerWidth < 1200;
    const [intervalId, setIntervalId] = useState(null);
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState({});

    const bodyStyle = {
        background: '#000',
    };
    const canvasStyle = {
        display: 'block',
    };
    const headerStyle = {
        fontSize: smallScreen ? '4rem' : (mediumScreen ? '8rem' : '12rem'),
    };

    const timerStyle = {
            fontSize: smallScreen ? '2rem' : mediumScreen ? '3.5rem' : '5rem',
            position: 'relative',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center', // Vertically center items in the container
        justifyContent: 'flex-start',
        position: 'relative',
        padding: '1rem'// Position relative to place absolute elements inside
    };

    const countdownStyle = {
        position: 'relative',
        fontFamily: 'digital-clock',
        marginLeft: "1rem"
    };
    const textStyle = {
        position: 'absolute',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        color: 'white',
        fontSize: smallScreen ? '0.35rem' : (mediumScreen ? '0.55rem' : '0.8rem'),
    };

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // Function to update state when window is resized
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // Function for Matrix animation
    const executeMatrixCode = useCallback(() => {
        const canvas = document.getElementById('yourCanvasID');
        if (!canvas) return;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        const ctx = canvas.getContext('2d');

        window.addEventListener('resize', () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        });

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890123456789@#$%^&*()_+-=[]{}|;:",.<>?/あいうえお0123456789へほ0123456789まみむめもやゆよらりるれろわゐゑをんアイウエオカキク0123456789ケコサシスセソタチツテトナニヌネノハヒフヘホマミ0123456789ムメモヤユヨラリルレロワヲン0123456789κλμνξοπρστυφχψω';
        const fontSize = 16;
        const columns = canvas.width / fontSize
        const drops = [];

        for (let i = 0; i < columns; i++) {
            drops[i] = 1;
        }

        function draw() {
            ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.fillStyle = '#0F0';
            ctx.font = fontSize + 'px Arial';
            for (let i = 0; i < drops.length; i++) {
                const text = characters.charAt(Math.floor(Math.random() * characters.length));
                ctx.fillText(text, i * fontSize, drops[i] * fontSize);

                if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
                    drops[i] = 0;
                }
                drops[i]++;
            }
        }

        if (intervalId) clearInterval(intervalId);
        // Set up a new interval for the matrix animation
        const newIntervalId = setInterval(draw, 50);
        setIntervalId(newIntervalId); // Store the new interval ID
        return () => clearInterval(newIntervalId);

    }, [intervalId])

    // Function to calculate countdown timer to November 1st
    const calculateTimeLeft = () => {
        // Obtenir la date actuelle à Paris
        const now = new Date(new Date().toLocaleString('en-EN', { timeZone: "Europe/Paris" }));

        // Définir la cible - 1er juillet 2024
        const targetDate = new Date(`July 1, 2024 00:00:00`);
        targetDate.setHours(targetDate.getHours()); // Ajustement pour l'heure d'été à Paris

        // Calculer la différence
        const difference = targetDate - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            }
        }

        return timeLeft;
    };


    // Run Matrix animation when component mounts
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        executeMatrixCode();
        setTimeLeft(calculateTimeLeft());

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(timer);
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimensions]);

    useEffect(() => {
        // When the landing page component mounts, remove the scrollbar styling
        document.documentElement.style.overflowY = 'auto'; // default value for overflowY

        // When the landing page component unmounts, reapply the scrollbar styling
        return () => {
            document.documentElement.style.overflowY = 'scroll'; // force scrollbar
        };
    }, []);

    return (
        <div style={bodyStyle} className="absolute h-full w-full flex flex-col">

            {/* Matrix Canvas */}
            <canvas id="yourCanvasID" className="absolute top-0 left-0 w-full h-full" style={canvasStyle}></canvas>

            <div style={{ ...timerStyle, ...containerStyle }}>
                {/* Countdown Timer */}
                <div style={countdownStyle}>
                    {String(timeLeft.days).padStart(2, '0')}:{String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:{String(timeLeft.seconds).padStart(2, '0')}
                    {/* WINTER 2023 positioned at the top right*/}
                    <div className="text-white font-bold absolute top-0 right-0" style={{ ...textStyle, transform: 'translateY(40%)' }}>SUMMER 2024</div>
                    {/* UNCONVENTIONAL BEAUTY positioned at the bot left*/}
                    <div className="text-white font-bold absolute bottom-0 left-0" style={{ ...textStyle, transform: 'translateY(-90%)' }}>UNCONVENTIONAL BEAUTY</div>
                    {/* ISSUE 001 positioned at the bottom of UNCODED:1 */}
                    <div className="text-white font-bold absolute bottom-0 left-1/2 transform -translate-x-1/2" style={{ ...textStyle,  }}>ISSUE 001</div>
                </div>
            </div>

            {/* Toggle Button */}
            <div className="absolute top-4 right-5 z-50">
                <button onClick={() => setOverlayOpen(!isOverlayOpen)} className="flex flex-col justify-center p-2 w-8 h-8 relative">
                    <div className={`bg-white h-1 w-5 absolute transition-transform transform ${isOverlayOpen ? 'rotate-45 top-1/2' : 'top-1/4'}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                    <div className={`bg-white h-1 w-5 absolute middle transition-opacity ${isOverlayOpen ? 'opacity-0' : ''}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                    <div className={`bg-white h-1 w-5 absolute transition-transform transform ${isOverlayOpen ? '-rotate-45 top-1/2' : 'bottom-1/4'}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                </button>
            </div>

            {/* Centered Content */}
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <h1 className="text-white font-bold mb-4 relative -mt-12" style={{ ...headerStyle, fontFamily: 'Impact, Charcoal, sans-serif', whiteSpace: 'nowrap'  }}>UNCODED: 1.</h1>
            </div>
            {isOverlayOpen && <Overlay onClose={() => setOverlayOpen(false)} isOnLanding={true} />}
        </div>
    );

};

export default LandingPage;
