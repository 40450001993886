import { Link } from 'react-router-dom'
import instagramLogo from '../data/instagram_logo_icon.png'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Overlay = ({ onClose, isOnLanding }) => {
    let smallScreen = window.innerWidth < 600;
    let mediumScreen= window.innerWidth >= 600 && window.innerWidth < 1200;

    const { t, i18n } = useTranslation()
    const [language, setLanguage] = useState(i18n.language);

    const changeLanguage = () => {
        let lng
        if (language === 'en'){
            lng = 'fr'
        }
        else {
            lng = 'en'
        }
        i18n.changeLanguage(lng).then(r => {
            setLanguage(lng);
        });
    }

    const overlayStyle = {
        paddingRight: isOnLanding ? `15px` : '0px',
    };

    const languageButtonStyle = {
        fontSize: smallScreen ? '1rem' : (mediumScreen ? '1.5rem' : '2rem'),
        cursor: 'pointer',
    };

    const linkStyle = {
        fontFamily: 'avenir-light',
        fontSize: smallScreen ? '0.75rem' : (mediumScreen ? '0.85rem' : '1rem'),
    };

    const uncodedButtonStyle = {
        fontSize: smallScreen ? '2.5rem' : (mediumScreen ? '4rem' : '5rem'),
    };

    const toggleLinkStyle = {
        fontFamily: 'avenir-medium',
        fontSize: smallScreen? '1rem' : '1.4rem',
        padding: '0.5rem',
        marginBottom: '2rem',
        color: '#000',
        display: 'block'
    }
    return (
        <div className="fixed top-0 left-0 h-full w-full bg-white z-50" style={overlayStyle}>
            <div className="p-8">
                <div className="relative flex items-center top-0 justify-center">
                    <Link to="/" onClick={onClose} className="text-black font-bold mb-6" style={{...uncodedButtonStyle,  fontFamily: 'Impact, Charcoal, sans-serif' }}>UNCODED: 1.</Link>
                </div>
                <hr className="w-full mx-4"/>
                <div className="mt-8 justify-center flex flex-col items-center relative">
                    <Link to="https://uncodedmagazine-edito.myportfolio.com/" onClick={onClose} style={toggleLinkStyle} className="hover:underline">EDITORIAL</Link>
                    <Link to="/about" onClick={onClose} style={toggleLinkStyle} className="hover:underline">{t('landingPage.ABOUT')}</Link>
                    <Link to="/career" onClick={onClose} style={toggleLinkStyle} className="hover:underline">{t('landingPage.CAREER')}</Link>
                    <Link to="/contact" onClick={onClose} style={toggleLinkStyle} className="hover:underline">{t('landingPage.CONTACT')}</Link>
                    <a href="https://instagram.com/uncodedmagazine?igshid=NGVhN2U2NjQ0Yg==" target="_blank" rel="noopener noreferrer">
                        <img style={{ width: '30px', height: '30px' }} src={instagramLogo} alt="Instagram" />
                    </a>
                </div>
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 mb-4">
                    <button style={languageButtonStyle} onClick={() => changeLanguage()}>
                        {language === 'en' ? '🇬🇧' : '🇫🇷'}
                    </button>
                </div>
                {/*<div style={{ position: 'absolute', left: '50%', bottom: '0', transform: 'translateX(-50%)' }}>*/}
                {/*    <button style={languageButtonStyle} onClick={() => changeLanguage()}>*/}
                {/*        {language === 'en' ? '🇬🇧' : '🇫🇷'}*/}
                {/*    </button>*/}
                {/*</div>*/}
                <div className="absolute bottom-4 right-4 text-black" style={linkStyle}>
                    <Link onClick={onClose} to="/legal">{t('landingPage.LEGAL_MENTION')}</Link>
                </div>
                <button onClick={onClose} className="absolute top-2 right-8 text-black text-4xl">&times;</button>
            </div>
        </div>
    );
};

export default Overlay