import { Link } from 'react-router-dom'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Config from '../config'
import Overlay from './ToggleMenu'
import { CareerContext } from '../App';

const CareerPage = () => {
    const [fontSize, setFontSize] = useState("20px");
    const [fontTitleSize, setFontTitleSize] = useState("25px");
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const offersFromContext = useContext(CareerContext);
    const [offers, setOffers] = useState(offersFromContext);
    const { t, i18n } = useTranslation()
    const [language, setLanguage] = useState(i18n.language);
    let smallScreen = window.innerWidth < 600;
    let mediumScreen= window.innerWidth >= 600 && window.innerWidth < 1200;

    const paragraphStyle = {
        fontSize,
    }

    const titleStyle = {
        fontSize: fontTitleSize
    }

    const uncodedButtonStyle = {
        fontSize: smallScreen ? '2.5rem' : (mediumScreen ? '4rem' : '5rem'),
    };


    const titleButtonStyle = {
        fontSize: smallScreen ? '1.5rem' : (mediumScreen ? '2rem' : '3rem'),
    };

    useEffect(() => {
        // Function to fetch offers based on the current language
        const fetchOffers = (lng) => {
            axios.get(`${Config.strapiApiUrl}/api/career-offers?locale=${lng}`)
                .then(response => {
                    setOffers(response.data.data);
                })
                .catch(error => {
                    console.log('An error occurred:', error.response);
                });
        };

        // Fetch offers initially and whenever the language changes
        fetchOffers(language);

        // Listen for language changes
        const handleLanguageChange = (lng) => {
            setLanguage(lng);
            fetchOffers(lng);
        };

        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup listener when component unmounts
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, language]);

    useEffect(() => {
        const handleResize = () => {
            setFontSize(window.innerWidth < 600 ? '12px' : (window.innerWidth >= 600 && window.innerWidth < 1200 ? '20px' : '24px'));
            setFontTitleSize(window.innerWidth < 600 ? '20px' : (window.innerWidth >= 600 && window.innerWidth < 1200 ? '25px' : '30px'));
        };
        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Call it once to set the initial state
        handleResize();

        // Cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty depend

    return (
        <div className="relative h-full w-full flex flex-col items-center bg-white" style={{ marginRight: '17px' }}>
            <div className="relative max-w-screen-lg w-full h-full text-center p-8">
                <Link to="/">
                    <h1 className="text-black font-bold"
                        style={{...uncodedButtonStyle,  fontFamily: 'Impact, Charcoal, sans-serif' }}>UNCODED: 1.</h1>
                </Link>
                <h2 className="mt-12 text-black"
                    style={{...titleButtonStyle, fontFamily: 'avenir-medium' }}>{t('careerPage.CAREER')}</h2>
                {/*Display career offer data fetched*/}
                {offers.map((offer) => (
                    <div key={offer.id}>
                        <hr className="mt-20 mb-20" style={{
                            border: '0',
                            height: '2px',
                            backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))'
                        }} />
                        <p className="mt-6 text-xl text-black mb-12"
                           style={{ ...titleStyle, fontFamily: 'avenir-medium' }}>{offer.attributes.Title}</p>
                        <p className="mt-6 text-xl text-black"
                           style={{ ...paragraphStyle, fontFamily: 'avenir-light' }}>{offer.attributes.Description}</p>
                        <p className="mt-12 text-xl text-black"
                           style={{ ...paragraphStyle, fontFamily: 'avenir-light' }}>{t('careerPage.TASKS')}</p>
                        <div className="mt-6 text-xl text-black mb-6" style={{...paragraphStyle, fontFamily: 'avenir-light' }}>
                            {/* Split the task description by bullet points and render each as a paragraph */}
                            {offer.attributes.TaskDescription.split('•').map((task, index) => (
                                // Don't render an empty paragraph for the first split before the first bullet point
                                task && <p key={index}>• {task}</p>
                            ))}
                        </div>
                        <p className="mt-12 text-xl text-black mb-6"
                           style={{ ...paragraphStyle, fontFamily: 'avenir-light' }}>PROFIL</p>
                        <p className="mt-6 text-xl text-black mb-24" style={{
                            ...paragraphStyle,
                            fontFamily: 'avenir-light'
                        }}>{offer.attributes.ProfilDescription}</p>
                    </div>
                ))}s
            </div>
            {/* Toggle Button */}
            <div className="absolute top-4 right-5 z-50">
                <button onClick={() => setOverlayOpen(!isOverlayOpen)} className="flex flex-col justify-center p-2 w-8 h-8 relative">
                    <div className={`bg-black h-1 w-5 absolute transition-transform transform ${isOverlayOpen ? 'rotate-45 top-1/2' : 'top-1/4'}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                    <div className={`bg-black h-1 w-5 absolute middle transition-opacity ${isOverlayOpen ? 'opacity-0' : ''}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                    <div className={`bg-black h-1 w-5 absolute transition-transform transform ${isOverlayOpen ? '-rotate-45 top-1/2' : 'bottom-1/4'}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                </button>
            </div>
            {isOverlayOpen && <Overlay onClose={() => setOverlayOpen(false)} />}
        </div>
    );
}


export default CareerPage;
