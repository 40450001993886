import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "landingPage": {
                "ABOUT": "ABOUT",
                "CAREER": "CAREER",
                "CONTACT": "CONTACT",
                "LEGAL_MENTION": "Legal Mention",
                "HOME": "Home"
            },
            "aboutPage": {
                "ABOUT": "ABOUT",
                "P1": "UNCODED IS A BI-ANNUAL FASHION MAGAZINE THAT TENDS TO BE SUBVERSIVE AND NONCONFORMIST. WE WANT TO DEMYSTIFY AND CRACK FASHION'S CODES.",
                "P2": "WE ARE A PLATFORM TO PROMOTE TALENTS AND ARTISTS REGARDLESS OF THEIR NOTORIETY OR FOLLOWERS. THIS IS A SAFE PLACE FOR FASHION, ART AND LITERATURE LOVERS.",
                "P3": "OUR FIRST ISSUE « UN:CONVENTIONAL BEAUTY » WILL BE RELEASED ON NOVEMBER STARRING 4 COVERS CELEBRITIES.",
            },
            "contactPage": {
                "P1": "WE ARE NOW ACCEPTING SUBMISSIONS AND PITCHES FOR THE DIGITAL AND PRINT ISSUE.",
                "P2":"HOW TO APPLY : SUBMISSIONS@UNCODEDMAGAZINE.COM",
                "P3":"SEND US A MOODBOARD WITH A CLEAR STORY TELLING AND FULL TEAM CREDITS. CASTING, TEAM, LOCATION AND MOODBOARD HAVE TO BE APPROVED BEFOREHAND. IF YOU WISH TO MAKE ANY CHANGES AFTER OUR INITIAL APPROVAL, THESE SHOULD BE MENTIONED BEFORE SHOOTING AND APPROVED BY THE EDITORIAL TEAM. OTHERWISE, WE CANNOT GUARANTEE THE PUBLICATION OF THE STORY.",
                "NAME": "Name",
                "SEND": "SEND",
                "OTHER": "FOR OTHER QUESTIONS"
            },
            "legalPage": {
                "LEGAL": "LEGAL MENTION",
                "P1": "THE UNCODED WEBSITE IS PUBLISHED BY UNCODED, A SINGLE-SHAREHOLDER SIMPLIFIED JOINT-STOCK COMPANY (SASU), WITH A SHARE CAPITAL OF 1,000 EUROS.",
                "P2": "REGISTERED IN THE PARIS TRADE AND COMPANIES REGISTER UNDER THE NUMBER 978 91 493, WITH ITS HEADQUARTERS LOCATED AT 60 RUE FRANÇOIS 1ER, 75008 PARIS. N° TVA FR30978911493.",
                "P3": "THE PRESIDENT OF UNCODED AND THE DIRECTOR OF PUBLICATION IS MR. JULIEN CARRAUD VASSEL.",
                "P4": "THE DEVELOPMENT, MAINTENANCE, AND HOSTING OF THE SITE IS PROVIDED BY QUENTIN ROBERT DE BEAUCHAMP.",
                "CONTACT": "CONTACT@UNCODEDMAGAZINE.COM-TEL.+33777757142"
            },
            "careerPage": {
                "CAREER": "CAREER",
                "TASKS": "TASKS INCLUDE"
            }
        }
    },
    fr: {
        translation: {
            "landingPage": {
                "ABOUT": "À PROPOS",
                "CAREER": "OFFRES D'EMPLOIS",
                "CONTACT": "CONTACT",
                "LEGAL_MENTION": "Mentions Légales",
                "HOME": "Menu"
            },
            "aboutPage": {
                "ABOUT": "À PROPOS",
                "P1": "UNCODED EST UN MAGAZINE DE MODE BI-ANNUEL, PUBLIÉ EN FORMAT DIGITAL ET PAPIER, QUI TEND À ÊTRE SUBVERSIF ET NON CONFORMISTE. NOUS VOULONS DÉMYSTIFIER ET CRACKER LES CODES DE LA MODE.",
                "P2": "NOUS SOMMES UNE PLATEFORME QUI PROMEUT LES TALENTS ET ARTISTES SANS TENIR COMPTE DE LEUR NOTORIÉTÉ OU DU NOMBRE DE FOLLOWERS QU'ILS ONT. C'EST UN ENDROIT SAIN POUR LES AMOUREUX DE LA MODE, DE L'ART ET DE LA LITTÉRATURE.",
                "P3": "NOTRE PREMIER NUMÉRO « BEAUTÉ INCONVENTIONNELLE » SERA PUBLIÉ EN NOVEMBRE, METTANT EN AVANT 4 CELEBRITES EN COUVERTURE.",
            },
            "contactPage": {
                "P1": "NOUS ACCEPTONS MAINTENANT LES PROPOSITIONS ET LES DEMANDES POUR LE NUMÉRO DIGITAL ET MAGAZINE PAPIER.",
                "P2":"COMMENT POSTULER : SUBMISSIONS@UNCODEDMAGAZINE.COM",
                "P3":"VEUILLEZ NOUS ENVOYER UNE PLANCHE D'INSPIRATIONS AVEC UNE HISTOIRE CLAIRE ET TOUTES LES MENTIONS DE L'ÉQUIPE. MODÈLES, ÉQUIPE, LIEU ET MOODBOARD DOIVENT ÊTRE VALIDÉS AU PRÉALABLE. SI VOUS SOUHAITEZ FAIRE DES MODIFICATIONS APRÈS NOTRE APROBATION DE L'ENVOI INITIAL, CELLES-CI DOIVENT ÊTRE PRÉCISÉES AVANT LE SHOOTING ET APPROUVÉES PAR L'ÉQUIPE ÉDITORIALE. AUTREMENT, NOUS NE POURRONS PAS GARANTIR LA PUBLICATION DE L’EDITORIAL.",
                "NAME": "Nom",
                "SEND": "ENVOYER",
                "OTHER": "POUR TOUTES AUTRES QUESTIONS"
            },
            "legalPage": {
                "LEGAL": "MENTIONS LÉGALES",
                "P1": "LE SITE « UNCODED » EST ÉDITÉ PAR UNCODED, SOCIÉTÉ PAR ACTIONS SIMPLIFIÉES UNIPERSONNELLE (SASU), AU CAPITAL SOCIAL DE 1000 EUROS.",
                "P2": "IMMATRICULÉE AU REGISTRE DU COMMERCE ET DES SOCIÉTÉS DE PARIS SOUS LE NUMERO 978 91 493, DONT LE SIÈGE SOCIAL EST SITUÉ 60 RUE FRANÇOIS 1ER,75008 PARIS. N° TVA FR30978911493.",
                "P3": "LE PRÉSIDENT DE UNCODED ET DIRECTEUR DE LA PUBLICATION EST MONSIEUR JULIEN CARRAUD VASSEL. ",
                "P4": "LE DEVELOPPEMENT, LA MAINTENANCE ET L'HÉBERGEMENT DU SITE EST ASSURÉ PAR QUENTIN ROBERT DE BEAUCHAMP.",
                "CONTACT": "CONTACT@UNCODEDMAGAZINE.COM-TEL.+33777757142"
            },
            "careerPage": {
                "CAREER": "OFFRES D'EMPLOIS",
                "TASKS": "MISSIONS",

            }
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", // default language
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
