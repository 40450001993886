import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import BaseRouter from './Router';
import './App.css';
import i18n from './i18n'; // import your i18n.js
import { I18nextProvider } from 'react-i18next';
import axios from 'axios';
import Config from './config';


export const CareerContext = React.createContext()

function App() {
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        const preloadData = async () => {
            try {
                const response = await axios.get(`${Config.strapiApiUrl}/api/career-offers?locale=en`);
                setOffers(response.data.data);
            } catch (error) {
                console.error('Erreur lors du chargement des offres d\'emploi:', error);
            }
        };

        preloadData();
    }, []);

    return (
        <CareerContext.Provider value={offers}>
            <I18nextProvider i18n={i18n}>
                <Router>
                    <div className="flex flex-col h-full text-white">
                        <BaseRouter />
                    </div>
                </Router>
            </I18nextProvider>
        </CareerContext.Provider>
    );
}

export default App;
