import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Overlay from './ToggleMenu'
import { useTranslation } from 'react-i18next'

const AboutPage = () => {
    const [fontSize, setFontSize] = useState("20px")
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    let smallScreen = window.innerWidth < 600;
    let mediumScreen= window.innerWidth >= 600 && window.innerWidth < 1200;
    const { t } = useTranslation()
    const paragraphStyle = {
        fontSize
    }

    const uncodedButtonStyle = {
        fontSize: smallScreen ? '2.5rem' : (mediumScreen ? '4rem' : '5rem'),
    };

    const titleButtonStyle = {
        fontSize: smallScreen ? '1.5rem' : (mediumScreen ? '2rem' : '3rem'),
    };

    useEffect(() => {
        const handleResize = () => {
            setFontSize(window.innerWidth < 600 ? '12px' : (window.innerWidth >= 600 && window.innerWidth < 1200) ? '20px' : '24px');
        };
        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Call it once to set the initial state
        handleResize();

        // Cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty depend


    return (
        <div className="relative h-full w-full flex flex-col items-center bg-white">
            <div className="relative max-w-screen-lg w-full h-full text-center p-8">
                <Link to="/">
                    <h1 className="text-black font-bold justify-center" style={{...uncodedButtonStyle,  fontFamily: 'Impact, Charcoal, sans-serif' }}>UNCODED: 1.</h1>
                </Link>
                <h2 className="mt-12 text-black" style={{...titleButtonStyle, fontFamily: 'avenir-medium' }}>{t('aboutPage.ABOUT')}</h2>
                <p className="mt-12 text-xl text-black" style={{...paragraphStyle, fontFamily: 'avenir-light' }}>{t('aboutPage.P1')}</p>
                <p className="mt-16 text-xl text-black" style={{...paragraphStyle, fontFamily: 'avenir-light' }}>{t('aboutPage.P2')}</p>
                <p className="mt-16 text-xl text-black" style={{...paragraphStyle, fontFamily: 'avenir-light' }}>{t('aboutPage.P3')}</p>
            </div>
            {/* Toggle Button */}
            <div className="absolute top-4 right-5 z-50">
                <button onClick={() => setOverlayOpen(!isOverlayOpen)} className="flex flex-col justify-center p-2 w-8 h-8 relative">
                    <div className={`bg-black h-1 w-5 absolute transition-transform transform ${isOverlayOpen ? 'rotate-45 top-1/2' : 'top-1/4'}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                    <div className={`bg-black h-1 w-5 absolute middle transition-opacity ${isOverlayOpen ? 'opacity-0' : ''}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                    <div className={`bg-black h-1 w-5 absolute transition-transform transform ${isOverlayOpen ? '-rotate-45 top-1/2' : 'bottom-1/4'}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                </button>
            </div>
            {isOverlayOpen && <Overlay onClose={() => setOverlayOpen(false)} />}
        </div>
    );
}

export default AboutPage;
