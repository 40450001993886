import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Overlay from './ToggleMenu'

const ContactPage = () => {
    const [fontSize, setFontSize] = useState("20px");
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const { t } = useTranslation()
    let smallScreen = window.innerWidth < 600;
    let mediumScreen= window.innerWidth >= 600 && window.innerWidth < 1200;

    const paragraphStyle = {
        fontSize
    }

    const uncodedButtonStyle = {
        fontSize: smallScreen ? '2.5rem' : (mediumScreen ? '4rem' : '5rem'),
    };

    const titleButtonStyle = {
        fontSize: smallScreen ? '1.5rem' : (mediumScreen ? '2rem' : '3rem'),
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const name = e.target.username.value;
        const email = e.target.email.value;
        const message = e.target.message.value;

        const mailtoLink = `mailto:contact@uncodedmagazine.com?subject=Contact from Website&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
        window.location.href = mailtoLink;
    }

    useEffect(() => {
        const handleResize = () => {
            setFontSize(window.innerWidth < 600 ? '15px' : (window.innerWidth >= 600 && window.innerWidth < 1200) ? '20px' : '24px');
        };
        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Call it once to set the initial state
        handleResize();

        // Cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty depend

    return (
        <div className="relative h-full w-full flex flex-col items-center bg-white">
            <div className="relative max-w-screen-lg w-full h-full text-center p-8">
            <Link to="/">
                <h1 className="text-black font-bold" style={{...uncodedButtonStyle,  fontFamily: 'Impact, Charcoal, sans-serif' }}>UNCODED: 1.</h1>
            </Link>
            <h2 className="mt-12 text-black" style={{...titleButtonStyle, fontFamily: 'avenir-medium' }}>CONTACT</h2>
            <div>
                <p className="mt-16 text-xl text-black" style={{...paragraphStyle, fontFamily: 'avenir-light' }}>{t('contactPage.P1')} </p>
                <p className="mt-16 text-xl text-black font-bold" style={{...paragraphStyle, fontFamily: 'avenir-light' }}>{t('contactPage.P2')} </p>
                <p className="mt-16 text-xl text-black" style={{...paragraphStyle, fontFamily: 'avenir-light' }}>{t('contactPage.P3')} </p>
                <p className="mt-16 text-xl text-black font-bold" style={{...paragraphStyle, fontFamily: 'avenir-light' }}>{t('contactPage.OTHER')} : </p>
            </div>
            <div className="mt-16">
                <form className="text-black" style={{...paragraphStyle, fontFamily: 'avenir-light' }} onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-xl mb-2" htmlFor="username">{t('contactPage.NAME')} :</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-black" id="username" type="text" placeholder="Your name" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-xl mb-2" htmlFor="email">Email :</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-black" id="email" type="email" placeholder="Your email" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-xl mb-2" htmlFor="message">Message :</label>
                        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-black" id="message" placeholder="Your message" rows="4" required></textarea>
                    </div>
                    <div className="flex items-center justify-center">
                        <button className="bg-black text-white font-bold py-2 px-4 rounded" type="submit">
                            {t('contactPage.SEND')}
                        </button>
                    </div>
                </form>
            </div>
            </div>
            {/* Toggle Button */}
            <div className="absolute top-4 right-5 z-50">
                <button onClick={() => setOverlayOpen(!isOverlayOpen)} className="flex flex-col justify-center p-2 w-8 h-8 relative">
                    <div className={`bg-black h-1 w-5 absolute transition-transform transform ${isOverlayOpen ? 'rotate-45 top-1/2' : 'top-1/4'}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                    <div className={`bg-black h-1 w-5 absolute middle transition-opacity ${isOverlayOpen ? 'opacity-0' : ''}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                    <div className={`bg-black h-1 w-5 absolute transition-transform transform ${isOverlayOpen ? '-rotate-45 top-1/2' : 'bottom-1/4'}`} style={{ left: '50%', transformOrigin: 'center', marginLeft: '-50%' }}></div>
                </button>
            </div>
            {isOverlayOpen && <Overlay onClose={() => setOverlayOpen(false)} />}
        </div>
    )
}

export default ContactPage;
